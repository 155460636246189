import "./Home.scss";

function Home() {
    return(
        <section>
            <h1>Hi, my name is Janet Leung</h1>
            <h2>Welcome to my portfolio</h2>
        </section>
    )
}

export default Home;